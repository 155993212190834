<template>
  <Dialog v-show="open" class="backdrop-blur-sm" :extend-class="['md:max-w-xl mb-[2rem] mt-[15rem]']">
    <div class="my-6 bg-white rounded- w-full">
      <div class="flex justify-between px-6">
        <div class="w-full">
          <div class="flex justify-between items-start">
            <div class="mb-4">
              <h1 class="text-[20px] font-semibold leading-[130%] text-brand-black mb-2">Share ”{{ appDetails?.name }}'s” Profile</h1>
              <div class="flex space-x-3 items-center">
                <img src="../../assets/icons/infoline.svg" alt="" />
                <small class="text-light-gray">You are sharing a candidate profile</small>
              </div>
            </div>

            <img src="../../assets/icons/closeline.svg" class="cursor-pointer" alt="" @click="closeModal" />
          </div>

          <div class="overflow-y-auto pb-10">
            <div class="pt-4 mb-6 border border-[#F0F0F0] rounded-lg">
              <div class="w-full border-b border-[#F0F0F0] px-6 pb-3">
                <div class="flex items-end space-x-3">
                  <span>To</span>
                  <input
                    type="email"
                    class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 placeholder:bg-light-gray-100 text-base grow"
                    placeholder="Type an email address..."
                    :value="appDetails?.email"
                  />
                </div>
              </div>
              <div class="w-full border-b border-[#F0F0F0] px-6 py-3 pb-4">
                <div class="flex items-end space-x-3">
                  <span>Subject</span>
                  <input
                    type="text"
                    class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 grow"
                    placeholder="Candidate Profile"
                    v-model="details.subject"
                  />
                </div>
              </div>
              <div class="w-full border-b border-[#F0F0F0]">
                <QuillEditor v-model:content="details.content" contentType="html" class="bg-white" theme="snow" toolbar="minimal" />
              </div>
            </div>

            <div class="flex justify-end space-x-4">
              <button @click="closeModal" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
              <button class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2" @click="shareProfile">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { alert } from '@/utils/helper';
import { ref } from 'vue';
import { useStore } from 'vuex';
import Dialog from '@/components/Dialog.vue';

const store = useStore();
const details = ref({
  email: '',
  subject: '',
  content: `Hi Cecilia,

Thank you very much for applying for the Engineering Manager position at Pixel Office.

Please be informed that we have received your application. Our hiring team is currently reviewing all applications. If you are among qualified candidates, you will receive an email notifying you of the next step soon.

Thanks again for your interest in working at our company.

Best regards,

Pixel Office`,
});

const props = defineProps({
  open: { type: Boolean },
  appDetails: { type: Object },
});

const emit = defineEmits(['onClose', 'update', 'onApply']);

const closeModal = () => {
  emit('onClose');
};

const shareProfile = async () => {
  try {
    const data = {
      ...details.value,
      email: props.appDetails?.email,
      candidates: [props.appDetails?.uuid],
    };
    const res = await store.dispatch('global/shareTalentProfile', data);
    if (res.status === true) {
      alert(res.message, 'success');
      closeModal();
    }
  } catch (error) {
    alert(error.response.data.message, 'error');
  }
};
</script>
